import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout";



// import '../css/index.css'; // add some style if you want!

export default function Index ({ data }) {

  const months = ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'];

  


  const { edges: posts } = data.allMarkdownRemark
  return (

    <Layout>

      <Helmet title={`miszczuk.org`} />


      <div className="">

        
        {posts
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            return (
              <div  className="uk-grid">
              <div className="uk-width-1-6@m uk-visible@m uk-margin-top ">
                <p style={{maxWidth:'64px', textAlign:'left'}} className="uk-text-meta uk-margin-top">
                  <h4 className="uk-margin-remove">{ post.frontmatter.date.split(' ')[0] } { months[post.frontmatter.date.split(' ')[1]-1] }</h4>
                  <h3 className="">{ post.frontmatter.date.split(' ')[2] }</h3>

                  {/* {post.frontmatter.tags &&
                    post.frontmatter.tags.length ? (
                      <>
                        {post.frontmatter.tags.map(tag => (
                          <p key={tag + `tag`}>
                            {tag}
                          </p>
                        ))}
                      </>
                  ) : null} */}

                  </p>
                </div>
                <div className="uk-width-1-1@s uk-width-5-6@m">

                  <article className="uk-article" >

                    <div className="uk-card uk-card-default uk-card-hover uk-margin" key={post.id}>
                      <div className="uk-card-body ">
                        <h3 className="uk-card-title"><Link to={post.frontmatter.path}>{post.frontmatter.title}</Link></h3>

                        <p>{post.frontmatter.excerpt}</p>
                      </div>
                    </div>
                  </article>

                </div>
              </div>
            

            )
        })}
      </div>
   
    </Layout>
  )
}

export const pageQuery = graphql`


  query IndexQuery {
    allMarkdownRemark(
        sort: { 
          order: DESC,
          fields: [frontmatter___date] 
        }
        filter: {
          frontmatter: { 
            tags: { glob: "*" }
            published: { eq: true } 
          }
        }
      
      ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            date(formatString: "DD M YYYY")
            updated
            description
            category
            tags
            excerpt
            
          }
        }
      }
    }
  }
`